<template>
  <PageLayout>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-one-third">
            <div class="has-padding">
              <div class="box">
                <transition name="fade" mode="out-in">
                  <!-- Register new account -->
                  <div key="register" v-if="activeView === 'register'">
                    <div
                      class="title is-4"
                      v-html="
                        $t('Components.AccountControls.Register.Main_Title')
                      "
                    ></div>
                    <Register
                      :labelForInput="false"
                      :showPasswordField="true"
                      :channelId="0"
                      :backFunc="backAction"
                      @nextAction="profileCreated"
                    />
                  </div>

                  <!-- Confirm new account -->
                  <div
                    v-else-if="activeView === 'profileCreated'"
                    key="profilecreated"
                  >
                    <div
                      class="title is-4"
                      v-html="
                        $t('Views.Account.Register.Main_Title_ProfileCreated', {
                          name: true || profile.FirstName,
                        })
                      "
                    ></div>

                    <Message
                      v-if="errorConfirmProfile"
                      class="is-danger"
                      :message="errorConfirmProfile"
                    ></Message>

                    <div
                      class="has-margin-bottom"
                      v-html="$t('Views.Account.Register.Text_ProfileCreated')"
                    ></div>

                    <ui-base-field-template
                      :labelForInput="false"
                      :label="
                        $t('Views.Account.Register.Label_ConfirmationCode')
                      "
                      :required="true"
                    >
                      <template v-slot:fields>
                        <div class="level is-inline-flex input-codes is-mobile">
                          <div class="level-item">
                            <input
                              type="text"
                              ref="codePart1"
                              @keyup="checkCodePart1"
                              size="2"
                              max="2"
                              maxlength="2"
                              name="codePart1"
                              class="input has-text-centered"
                              :class="[
                                {
                                  'is-danger':
                                    errorConfirmProfile ||
                                    (errorCodeMessage && !code),
                                },
                              ]"
                            />
                          </div>
                          <div class="level-item">
                            <font-awesome-icon :icon="['far', 'minus']" />
                          </div>
                          <div class="level-item">
                            <input
                              type="text"
                              ref="codePart2"
                              @keyup="checkCodePart2"
                              size="2"
                              max="2"
                              maxlength="2"
                              name="codePart2"
                              class="input has-text-centered"
                              :class="[
                                {
                                  'is-danger':
                                    errorConfirmProfile ||
                                    (errorCodeMessage && !code),
                                },
                              ]"
                            />
                          </div>
                          <div class="level-item">
                            <font-awesome-icon :icon="['far', 'minus']" />
                          </div>
                          <div class="level-item">
                            <input
                              type="text"
                              ref="codePart3"
                              @keyup="checkCodePart3"
                              size="2"
                              max="2"
                              maxlength="2"
                              name="codePart3"
                              class="input has-text-centered"
                              :class="[
                                {
                                  'is-danger':
                                    errorConfirmProfile ||
                                    (errorCodeMessage && !code),
                                },
                              ]"
                            />
                          </div>
                        </div>
                        <p v-if="errorCodeMessage">
                          <span class="help is-danger">
                            {{ errorCodeMessage }}
                          </span>
                        </p>
                      </template>
                    </ui-base-field-template>

                    <div class="has-text-right has-padding-top">
                      <button
                        @click="handleConfirmProfile"
                        :disabled="isProcessing"
                        class="button is-success"
                        :class="[
                          {
                            'is-disabled': isProcessing,
                            'is-loading': isProcessing,
                          },
                        ]"
                      >
                        <span
                          v-html="
                            $t('Views.Account.Register.Button_ConfirmProfile')
                          "
                        ></span>
                      </button>
                    </div>
                  </div>

                  <!-- Account confirmed -->
                  <div
                    v-else-if="activeView === 'profileConfirmed'"
                    key="profileConfirmed"
                  >
                    <div
                      class="title is-4 "
                      v-html="
                        $t('Views.Account.Register.Main_Title_ProfileConfirm')
                      "
                    ></div>

                    <div
                      class="has-margin-bottom"
                      v-html="$t('Views.Account.Register.Text_ProfileConfirm')"
                    ></div>
                    <div class="has-text-right has-padding">
                      <router-link
                        tag="button"
                        class="button is-success"
                        :to="{ name: 'my-account-login' }"
                        v-html="$t('Views.Account.Register.Button_Login')"
                      ></router-link>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import loginProvider from '@/providers/login'
const PageLayout = () =>
  import(
    /* webpackChunkName: "my-account-register" */ '@/components/MainSiteElements/Frontend/PageLayout.vue'
  )
const Register = () =>
  import(
    /* webpackChunkName: "my-account-register" */ '@/components/AccountControls/Register'
  )
const Message = () =>
  import(
    /* webpackChunkName: "my-account-register" */ '@/components/UI/Message'
  )

export default {
  name: 'view-account-register',

  components: {
    Register,
    Message,
    PageLayout,
  },

  data() {
    return {
      activeView: 'register',
      profile: null,
      code: '',
      isProcessing: false,
      errorCodeMessage: '',
      failCode: 0,
      verificationKey: '',
    }
  },

  computed: {
    errorConfirmProfile() {
      if (this.failCode < 0) {
        return this.$t(
          'Views.Account.Register.Message_ConfirmProfile_ErrorCode-1'
        )
      } else if (this.failCode === 400) {
        return this.$t(
          'Views.Account.Register.Message_ConfirmProfile_ErrorCode400'
        )
      } else {
        return ''
      }
    },
  },

  beforeDestroy() {
    this.profile = null
  },

  methods: {
    onCodeValidated() {
      this.errorCodeMessage = ''
      if (this.code === '') {
        this.errorCodeMessage = this.$t('Form.InputErrors.RequiredField', {
          fieldLabel: this.$t('Views.Account.Register.Label_ConfirmationCode'),
        })
      } else if (this.failCode === -1) {
        this.errorCodeMessage = this.$t(
          'Views.Account.Register.Message_ConfirmProfile_ErrorCode-1'
        )
      }
    },

    backAction() {
      this.$router.go(-1)
    },

    profileCreated(data) {
      this.verificationKey = data.VerificationKey
      this.activeView = 'profileCreated'
    },

    checkCodePart1(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart2.focus()
      }
      this.updateCode()
    },

    checkCodePart2(e) {
      if (e.target.value.trim() !== '' && e.target.value.length === 2) {
        this.$refs.codePart3.focus()
      }
      this.updateCode()
    },

    checkCodePart3() {
      this.updateCode()
    },

    updateCode() {
      let output = ''
      output += this.$refs.codePart1 ? this.$refs.codePart1.value.trim() : ''
      output += this.$refs.codePart2 ? this.$refs.codePart2.value.trim() : ''
      output += this.$refs.codePart3 ? this.$refs.codePart3.value.trim() : ''
      this.code = output
      this.onCodeValidated()
    },

    handleConfirmProfile() {
      let self = this
      this.onCodeValidated()
      if (!this.errorCodeMessage && !this.isProcessing)
        loginProvider.methods
          .checkAuthPin(this.verificationKey, this.code)
          .then((response) => {
            if (response.status === 200) {
              //self.activeView = 'profileConfirmed'

              self.$store.dispatch(
                'profileStore/saveProfileToken',
                response.data.ProfileToken
              )

              this.$router.push({ name: 'my-account-dashboard' })
            } else {
              self.failCode = response.data
              self.isProcessing = false
            }
          })
    },
  },
}
</script>
