var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageLayout',[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-one-third"},[_c('div',{staticClass:"has-padding"},[_c('div',{staticClass:"box"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.activeView === 'register')?_c('div',{key:"register"},[_c('div',{staticClass:"title is-4",domProps:{"innerHTML":_vm._s(
                      _vm.$t('Components.AccountControls.Register.Main_Title')
                    )}}),_c('Register',{attrs:{"labelForInput":false,"showPasswordField":true,"channelId":0,"backFunc":_vm.backAction},on:{"nextAction":_vm.profileCreated}})],1):(_vm.activeView === 'profileCreated')?_c('div',{key:"profilecreated"},[_c('div',{staticClass:"title is-4",domProps:{"innerHTML":_vm._s(
                      _vm.$t('Views.Account.Register.Main_Title_ProfileCreated', {
                        name: true || _vm.profile.FirstName,
                      })
                    )}}),(_vm.errorConfirmProfile)?_c('Message',{staticClass:"is-danger",attrs:{"message":_vm.errorConfirmProfile}}):_vm._e(),_c('div',{staticClass:"has-margin-bottom",domProps:{"innerHTML":_vm._s(_vm.$t('Views.Account.Register.Text_ProfileCreated'))}}),_c('ui-base-field-template',{attrs:{"labelForInput":false,"label":_vm.$t('Views.Account.Register.Label_ConfirmationCode'),"required":true},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('div',{staticClass:"level is-inline-flex input-codes is-mobile"},[_c('div',{staticClass:"level-item"},[_c('input',{ref:"codePart1",staticClass:"input has-text-centered",class:[
                              {
                                'is-danger':
                                  _vm.errorConfirmProfile ||
                                  (_vm.errorCodeMessage && !_vm.code),
                              },
                            ],attrs:{"type":"text","size":"2","max":"2","maxlength":"2","name":"codePart1"},on:{"keyup":_vm.checkCodePart1}})]),_c('div',{staticClass:"level-item"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'minus']}})],1),_c('div',{staticClass:"level-item"},[_c('input',{ref:"codePart2",staticClass:"input has-text-centered",class:[
                              {
                                'is-danger':
                                  _vm.errorConfirmProfile ||
                                  (_vm.errorCodeMessage && !_vm.code),
                              },
                            ],attrs:{"type":"text","size":"2","max":"2","maxlength":"2","name":"codePart2"},on:{"keyup":_vm.checkCodePart2}})]),_c('div',{staticClass:"level-item"},[_c('font-awesome-icon',{attrs:{"icon":['far', 'minus']}})],1),_c('div',{staticClass:"level-item"},[_c('input',{ref:"codePart3",staticClass:"input has-text-centered",class:[
                              {
                                'is-danger':
                                  _vm.errorConfirmProfile ||
                                  (_vm.errorCodeMessage && !_vm.code),
                              },
                            ],attrs:{"type":"text","size":"2","max":"2","maxlength":"2","name":"codePart3"},on:{"keyup":_vm.checkCodePart3}})])]),(_vm.errorCodeMessage)?_c('p',[_c('span',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.errorCodeMessage)+" ")])]):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"has-text-right has-padding-top"},[_c('button',{staticClass:"button is-success",class:[
                        {
                          'is-disabled': _vm.isProcessing,
                          'is-loading': _vm.isProcessing,
                        },
                      ],attrs:{"disabled":_vm.isProcessing},on:{"click":_vm.handleConfirmProfile}},[_c('span',{domProps:{"innerHTML":_vm._s(
                          _vm.$t('Views.Account.Register.Button_ConfirmProfile')
                        )}})])])],1):(_vm.activeView === 'profileConfirmed')?_c('div',{key:"profileConfirmed"},[_c('div',{staticClass:"title is-4",domProps:{"innerHTML":_vm._s(
                      _vm.$t('Views.Account.Register.Main_Title_ProfileConfirm')
                    )}}),_c('div',{staticClass:"has-margin-bottom",domProps:{"innerHTML":_vm._s(_vm.$t('Views.Account.Register.Text_ProfileConfirm'))}}),_c('div',{staticClass:"has-text-right has-padding"},[_c('router-link',{staticClass:"button is-success",attrs:{"tag":"button","to":{ name: 'my-account-login' }},domProps:{"innerHTML":_vm._s(_vm.$t('Views.Account.Register.Button_Login'))}})],1)]):_vm._e()])],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }